import { toast } from "react-toastify";
import { axiosCall } from "../api/config/axiosCall";

/**
 * Asynchronously creates an item by sending a POST request to the specified URL with the given data.
 *
 * @template T - The type of the data being sent in the POST request.
 * @param {string} url - The URL to which the POST request is sent.
 * @param {T} data - The data to be sent in the POST request.
 * @returns {Promise<number>} A promise that resolves to:
 * - `response.data.id` if the request is successful and the server responds with a status of 201.
 * - `1` if the response status is not 201 but the request does not fail.
 * - `0` if there is an error during the request.
 */
const createItem = async <T,>(
  url: string,
  data: T,
  showToast: boolean = true
): Promise<number> => {
  try {
    const response = await axiosCall({
      endpoint: url,
      data,
    });
    if (response?.status === 201) {
      if (showToast) {
        toast.success("Element erfolgreich gespeichert");
      }
      return response?.data?.id;
    }
    return 1;
  } catch (error) {
    toast.error("Fehler beim Speichern");
    return 0;
  }
};

export default createItem;
