import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/FormComponent.css";
import { DropdownOption } from "../../utils/interfaces/dropdowns";
import { fetchDropDownOptions } from "./FormComponent.utils";

/**
 * Interface representing an individual input field in the form.
 */
export interface InputField {
  label: string;
  placeholder?: string;
  id: string;
  type: any;
  required?: boolean;
  options?: string[];
  value: any;
  url?: string;
  flexible?: boolean;
  handleInputChange: (params: { id: string; value: any }) => void;
  mask?: boolean;
  copyable?: boolean;
}

/**
 * Interface representing a section within the form.
 * A section contains multiple input fields.
 */
interface Section {
  sectionTitle: string;
  fields: InputField[];
}

/**
 * The `FormComponent` renders a form based on the provided sections.
 * It allows for different input types and can be in either edit or view mode.
 *
 * @param sections - Array of sections, where each section contains multiple input fields.
 * @param editMode - Boolean indicating whether the form is in edit mode or view mode.
 * @param errors
 */
const FormComponent: React.FC<{
  sections: Section[];
  editMode: boolean;
  errors: Set<string>;
}> = ({ sections, editMode, errors }) => {
  const [dropdownOptions, setDropdownOptions] = useState<{
    [key: string]: DropdownOption[];
  }>({});

  /**
   * Fetches dropdown options for a given URL key if they have not already been fetched.
   *
   * @param urlKey - The URL to fetch the dropdown options from.
   */
  const getDropdownOptions = useCallback(
    async (urlKey: string) => {
      if (urlKey && !dropdownOptions[urlKey]) {
        try {
          const options: DropdownOption[] = await fetchDropDownOptions(urlKey);
          setDropdownOptions((prevOptions) => ({
            ...prevOptions,
            [urlKey]: options,
          }));
        } catch (error) {
          console.error("Error fetching dropdown options:", error);
        }
      }
    },
    [dropdownOptions]
  );

  // useEffect hook to fetch dropdown options when the component is in edit mode
  useEffect(() => {
    const fieldsWithUrls = sections
      .flatMap((section) => section.fields)
      .filter((field) => field.url);

    fieldsWithUrls.forEach((field) => {
      getDropdownOptions(field.url!);
    });
  }, [editMode, sections, getDropdownOptions]);

  /**
   * Handles the change event for select (dropdown) inputs.
   *
   * @param input - The current input field object.
   * @param selectedElement - The selected value in the dropdown.
   * @param url - The URL key for fetching the dropdown options.
   */
  const handleSelectChange = useCallback(
    (input: InputField, selectedElement: string, url: string) => {
      if (selectedElement === "") {
        input.handleInputChange({
          id: input.id,
          value: null,
        });
        return;
      }
      const parsedSelected = JSON.parse(selectedElement);
      const selectedId = parsedSelected.id;
      const selectedOption = dropdownOptions[url].find(
        (option) => option.id === selectedId
      );
      if (selectedOption) {
        input.handleInputChange({
          id: input.id,
          value: selectedOption,
        });
      }
    },
    [dropdownOptions]
  );

  /**
   * Handles the copy event for masked inputs, ensuring that the plain text is copied.
   *
   * @param event - The clipboard event triggered during copying.
   * @param value - The plain text value to be copied.
   */
  const handleCopy = (event: React.ClipboardEvent, value: string) => {
    event.preventDefault();
    event.clipboardData.setData("text/plain", value);
    toast.success("Klartext wurde kopiert!");
  };

  return (
    <div className="form-container">
      {sections.map((section, index) => (
        <div key={index} className="form-section">
          <h2>{section.sectionTitle}</h2>
          <div className="form-row">
            {section.fields.map((input) => (
              <div key={input.id} className="form-group">
                <label htmlFor={input.id}>
                  {input.label}: {input.required ? "*" : ""}
                </label>
                {input.type === "select" ? (
                  <select
                    id={input.id}
                    value={
                      editMode
                        ? input.value && input.value.id
                          ? JSON.stringify({
                              id: (input.value as DropdownOption)?.id,
                              name: (input.value as DropdownOption)?.name,
                            })
                          : ""
                        : input.value
                          ? input.value.id
                          : ""
                    }
                    onChange={(e) =>
                      handleSelectChange(input, e.target.value, input.url!)
                    }
                    disabled={!editMode}
                    className={errors.has(input.id) ? "input-error" : ""}
                  >
                    {editMode ? (
                      <>
                        <option value="" disabled>
                          {input.placeholder || "Bitte wählen"}
                        </option>
                        {dropdownOptions[input.url!]?.map((option) => (
                          <option
                            key={option.id}
                            value={JSON.stringify({
                              id: option.id,
                              name: option.name,
                            })}
                          >
                            {option.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        <option value=""></option>
                        {dropdownOptions[input.url!]?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                ) : input.type === "checkbox" ? (
                  <div className="slider-container">
                    <label className="switch">
                      <input
                        type="checkbox"
                        id={input.id}
                        checked={!!input.value}
                        onChange={(e) =>
                          input.handleInputChange({
                            id: input.id,
                            value: e.target.checked,
                          })
                        }
                        disabled={!editMode}
                        className={errors.has(input.id) ? "input-error" : ""}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                ) : input.mask ? (
                  <input
                    type="password"
                    id={input.id}
                    value={input.value as string}
                    onChange={(e) =>
                      input.handleInputChange({
                        id: input.id,
                        value: e.target.value,
                      })
                    }
                    onCopy={(e) => handleCopy(e, input.value)}
                    disabled={!editMode}
                  />
                ) : (
                  <input
                    type={input.type}
                    id={input.id}
                    placeholder={input.placeholder}
                    value={input.value || ""} // Empty string as fallback
                    onChange={(e) =>
                      input.handleInputChange({
                        id: input.id,
                        value: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    className={errors.has(input.id) ? "input-error" : ""}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormComponent;
