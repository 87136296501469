import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Table } from "../../../models/Table";
import {
  CREATE_TABLE_URL,
  UPDATE_TABLE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./tableInputFields.json";

export const initialData = {
  purchase_price: 0,
  identifier: "",
  bill_number: "",
  order_number: "",
  inventory_number: "",
  purchase_date: null,
  heightAdjustable: false,
  comment: "",
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedTable Setter for >editedTable<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedTable: Dispatch<React.SetStateAction<Table>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedTable((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (value) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedTable Table item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id Table id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedTable: Table,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (isNew) {
      await createItem<Table>(CREATE_TABLE_URL, editedTable);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      const { qr_code, ...newEditedTable } = editedTable;
      await updateItem<Table>(UPDATE_TABLE_URL, id, newEditedTable);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedTable Table item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedTable: Table,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedTable[field.id as keyof Table];

      // If nothing in there, then add error.
      if (field.required && !value) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
