import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Computer } from "../../../models/Computer";
import {
  CREATE_COMPUTER_URL,
  UPDATE_COMPUTER_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./computerInputFields.json";

export const initialState = {
  identifier: "",
  bios_password: "",
  diskCapacity: 0,
  diskByte: 0,
  disk_byte_details: {
    id: 0,
    name: "",
  },
  diskType: 0,
  disk_type_details: {
    id: 0,
    name: "",
  },
  hostname: "",
  manufacturer: "",
  model: "",
  ip: "",
  mac: "",
  os_name: "",
  os_version: "",
  processorManufacturer: "",
  processorName: "",
  processorCore: "",
  purchase_date: null,
  bill_number: "",
  order_number: "",
  memoryCapacity: 0,
  memoryType: 0,
  memory_type_details: {
    id: 0,
    name: "",
  },
  memoryByte: 0,
  memory_byte_details: {
    id: 0,
    name: "",
  },
  recoveryKey: "",
  encryptionKey: "",
  encryptionType: "",
  type: 0,
  type_details: {
    id: 0,
    name: "",
  },
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedComputer Setter for >editedComputer<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedComputer: Dispatch<React.SetStateAction<Computer>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedComputer((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedComputer Computer item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id Computer id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedComputer: Computer,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedComputer.purchase_date === "") {
      editedComputer.purchase_date = null;
    }
    editedComputer.diskByte = editedComputer.disk_byte_details.id;
    editedComputer.diskType = editedComputer.disk_type_details.id;
    editedComputer.memoryByte = editedComputer.memory_byte_details.id;
    editedComputer.memoryType = editedComputer.memory_type_details.id;
    editedComputer.type = editedComputer.type_details.id;
    if (isNew) {
      await createItem<Computer>(CREATE_COMPUTER_URL, editedComputer);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      const { qr_code, ...newEditedComputer } = editedComputer;
      await updateItem<Computer>(UPDATE_COMPUTER_URL, id, newEditedComputer);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedComputer Computer item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedComputer: Computer,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedComputer[field.id as keyof Computer];

      // If nothing in there, then add error.
      // Or: If value is an object but has nothing in there, then add error.
      if (
        field.required &&
        (!value ||
          (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
