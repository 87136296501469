import LogoutIcon from "@mui/icons-material/Logout";
import { Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useLogout } from "../../api/hooks/auth/useLogout";
import { useStyle } from "./Sidebar.styles";

const Sidebar = () => {
  const style = useStyle();
  const { logout } = useLogout();

  const general = [{ name: "Dashboard", path: "/" }];
  const settings = [{ name: "Profil", path: "/profile" }];
  const employees = [{ name: "Mitarbeitende", path: "/employee" }];

  const assets = [
    { name: "Büroausstattungen", path: "/accessory" },
    { name: "Computer", path: "/computer" },
    { name: "Festnetztelefone", path: "/landline" },
    { name: "Festnetzverträge", path: "/landlineContract" },
    { name: "Gebäude", path: "/building" },
    { name: "Literatur", path: "/literature" },
    { name: "Lizenzen", path: "/license" },
    { name: "Mobiltelefone", path: "/mobilephone" },
    { name: "Mobilfunkverträge", path: "/contract" },
    { name: "Monitore", path: "/monitor" },
    { name: "Netzwerkgeräte", path: "/networkDevice" },
    { name: "Räume", path: "/room" },
    { name: "Server", path: "/server" },
    { name: "Standort", path: "/location" },
    { name: "Tische", path: "/table" },
    { name: "Türzugänge", path: "/doorAccess" },
    { name: "Virtual Machines", path: "/virtualmachine" },
  ];

  return (
    <nav className="sidebar-container">
      <div style={style.logo}>
        <img
          style={style.logo_img}
          src={`${process.env.PUBLIC_URL}/Logo.png`}
          alt="Logo"
        />
        <span style={style.logo_name}>ziings</span>
      </div>

      <ul>
        {general.map((item) => (
          <li key={item.name}>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
        <Divider style={style.divider} />

        {settings.map((item) => (
          <li key={item.name}>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
        <li key="Logout">
          <NavLink to="#" onClick={logout}>
            <div style={style.logout}>
              Logout <LogoutIcon style={style.logout_icon} />
            </div>
          </NavLink>
        </li>
        <Divider style={style.divider} />

        {employees.map((item) => (
          <li key={item.name}>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
        <Divider style={style.divider} />

        {assets.map((item) => (
          <li key={item.name}>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
