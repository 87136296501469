import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Literature } from "../../../models/Literature";
import {
  CREATE_LITERATURE_URL,
  UPDATE_LITERATURE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./literatureInputFields.json";

export const initialData = {
  author: "",
  bill_number: "",
  comment: "",
  identifier: "",
  isbn: "",
  order_number: "",
  purchase_date: null,
  purchase_price: 0,
  title: "",
  type: 0,
  type_details: {
    id: 0,
    name: "",
  },
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedLiterature Setter for >editedLiterature<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedLiterature: Dispatch<React.SetStateAction<Literature>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedLiterature((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedLiterature Literature item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id Literature id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedLiterature: Literature,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedLiterature.purchase_date === "") {
      editedLiterature.purchase_date = null;
    }

    editedLiterature.type = editedLiterature.type_details.id;
    if (isNew) {
      await createItem<Literature>(CREATE_LITERATURE_URL, editedLiterature);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      const { qr_code, ...newEditedLiterature } = editedLiterature;
      await updateItem<Literature>(
        UPDATE_LITERATURE_URL,
        id,
        newEditedLiterature
      );
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedLiterature Literature item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedLiterature: Literature,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedLiterature[field.id as keyof Literature];

      // If nothing in there, then add error.
      // Or: If value is an object but has nothing in there, then add error.
      if (
        field.required &&
        (!value ||
          (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
