import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Server } from "../../../models/Server";
import {
  CREATE_SERVER_URL,
  UPDATE_SERVER_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./serverInputFields.json";

export const initialData = {
  identifier: "",
  bios_password: "",
  diskCapacity: 0,
  diskByte: 0,
  disk_byte_details: {
    id: 0,
    name: "",
  },
  diskType: 0,
  disk_type_details: {
    id: 0,
    name: "",
  },
  hostname: "",
  serialnumber: "",
  inventory_number: 0,
  manufacturer: "",
  model: "",
  ip: "",
  mac: "",
  os_name: "",
  os_version: "",
  processorManufacturer: "",
  processorName: "",
  processorCore: "",
  processor_number: 0,
  purchase_date: null,
  purchase_price: 0,
  memoryCapacity: 0,
  memoryType: 0,
  memory_type_details: {
    id: 0,
    name: "",
  },
  memoryByte: 0,
  memory_byte_details: {
    id: 0,
    name: "",
  },
  recoveryKey: "",
  encryptionKey: "",
  encryptionType: "",
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedServer Setter for >editedServer<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedServer: Dispatch<React.SetStateAction<Server>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedServer((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedServer item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedServer: Server,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedServer.purchase_date === "") {
      editedServer.purchase_date = null;
    }
    editedServer.diskByte = editedServer.disk_byte_details.id;
    editedServer.diskType = editedServer.disk_type_details.id;
    editedServer.memoryByte = editedServer.memory_byte_details.id;
    editedServer.memoryType = editedServer.memory_type_details.id;
    if (isNew) {
      await createItem<Server>(CREATE_SERVER_URL, editedServer);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      const { qr_code, ...newEditedServer } = editedServer;
      await updateItem<Server>(UPDATE_SERVER_URL, id, newEditedServer);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedServer item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedServer: Server,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedServer[field.id as keyof Server];

      // If nothing in there, then add error.
      // Or: If value is an object but has nothing in there, then add error.
      if (
        field.required &&
        (!value ||
          (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
