import { useNavigate } from "react-router-dom";
import FlexButton from "../button/FlexButton";
import { ButtonVariant } from "../button/FlexButton.interface";
import { OverviewHeaderProps } from "./OverviewHeader.interface";

/**
 * OverviewHeader component renders a header with a title and a button.
 * The button navigates to a create page for the given asset type.
 *
 * @param {OverviewHeaderProps} props - The properties for the OverviewHeader component.
 * @param {string} props.title - The title to display in the header.
 * @param {string} props.assetType - The type of asset for which the create page will be navigated to.
 * @returns {JSX.Element} A header element containing a title and a navigation button.
 */
const OverviewHeader = (props: OverviewHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-between align-items-center">
      <h1>{props.title}</h1>
      <FlexButton
        id={`add-${props.assetType}-button`}
        variant={ButtonVariant.TEXT_OUTLINED}
        title="Hinzufügen"
        onClick={() =>
          navigate(`/${props.assetType}/create`, {
            state: { editMode: true },
          })
        }
      />
    </div>
  );
};

export default OverviewHeader;
